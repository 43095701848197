import { User } from "oidc-client-ts"
import { LoaderFunctionArgs } from "react-router-dom";

export interface Simulation {
    id: number;
    name: string;
    lastUsedBy: string;
}


function getUser() {
    const oidcStorage = sessionStorage.getItem('oidc.user:https://auth.cppmodel.com/realms/CppModel:cppmodel-frontend')
    if (!oidcStorage) {
        return null;
    }
    // console.log('User:', oidcStorage);

    return User.fromStorageString(oidcStorage);
}

function getHostname() : string | null {
    const user = getUser();
    const groups = user?.profile.groups as string[]
    if (!groups) {
        return 'https://free.cppmodel.com';
    }
    const subdomain = groups.length > 0 ? groups[0] : 'free';
    return `https://${subdomain}.cppmodel.com`;
}

export const getAllUserSimulations = async (): Promise<Simulation[] | null> => {
    const user = getUser();
    const token = user?.access_token;
    const host = getHostname();
    console.log(`Host: ${host}`);
    // fetch simulations from localhost usign bearer token
    if (!host) {
        return null;
    }

    const data: Array<string> | null = await fetch(`${host}/api/simulations`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(async (response) => {
        // console.log(await response.text()); // Ensure response.text() is awaited
        const jsonData = await response.json(); // Parse JSON response
        return Array.isArray(jsonData) ? jsonData : null; // Ensure it's an array
    }).catch((error) => {
        console.error(error);
        return null; // Ensure null is returned even on error
    });
    if (!data) {
        return null;
    }
    // console.log(`Getting simulations for ${user?.profile.email}`);

    let result: Simulation[] = [];
    let ii = 1;
    data.forEach(sim => {
        result.push({id: ii++, name: sim, lastUsedBy: user?.profile.email ?? ""});
    });
    
    return result;
}

export const getAllTeamSimulations = async () => {
    const user = getUser();
    const token = user?.access_token;
    const host = getHostname();

    if (!host) {
        return null;
    }

    // console.log(`Host: ${host}`);
    // fetch simulations from localhost usign bearer token
    const data: Simulation[] | null = await fetch(`${host}/api/teamSimulations`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    }).then(async (response) => {
        // console.log(await response.text()); // Ensure response.text() is awaited
        const jsonData = await response.json(); // Parse JSON response
        // console.log(jsonData);
        // Convert the jsonData objet {LedBlink: 'hristo.hristoskov@ecologforestry.com', TestSimulation: 'hristo.hristoskov@ecologforestry.com'} to Simulation[]
        if (jsonData) {
            let result: Simulation[] = [];
            let ii = 1;
            Object.keys(jsonData).forEach(sim => {
                result.push({id: ii++, name: sim, lastUsedBy: jsonData[sim]});
            });
            return result;
        }
        return null;
    }).catch((error) => {
        console.error(error);
        return null; // Ensure null is returned even on error
    });
    if (!data) {
        return null;
    }
    // console.log(`Getting simulations for ${user?.profile.email}`);

    // let result: Simulation[] = [];
    // let ii = 1;
    // data.forEach(sim => {
    //     result.push({id: ii++, name: sim, lastUsedBy: user?.profile.email ?? ""});
    // });
    
    // console.log(data);

    return data;
}

export const getSimulationResults = async ({params}: LoaderFunctionArgs): Promise<any[] | null> => {
    const { simulationId } = params;
    const user = getUser();
    const host = getHostname();

    if (!host) {
        return null;
    }

    const data = await fetch(`${host}/api/simulations/${simulationId}`, {
        headers: {
            Authorization: `Bearer ${user?.access_token}`,
        },
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error(error);
    });

    if (!data) {
        return null;
    }

    return data;
}

// export const getPosts = createAsyncThunk(
//     "store/getPosts",
//     async () => {
//         const user = getUser();
//         const token = user?.access_token;
//         return fetch("https://api.example.com/posts", {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     },
//     // ...
// )