import React, { useState } from "react";
import {
  Alignment,
  AnchorButton,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Button,
  Popover,
  Tooltip,
  Menu,
  MenuItem
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Link, useLocation } from "react-router-dom";
import CreateSimulationModal from "./CreateSimulationModal";
import { useAuth } from "react-oidc-context";

export interface MainNavigationProps { }

export const MainNavigation: React.FC<MainNavigationProps> = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useAuth();
  const email = auth.user?.profile.email;
  const firstName = auth.user?.profile.given_name;
  const subscriptionAdmin = auth.user?.profile['subscription-role'] ?? null;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openUserProfile = () => {
    if (auth.user && auth.user.profile) {
      const keycloakUrl = auth.user.profile.iss; // issuer URL from user profile
      const profileUrl = `${keycloakUrl}/account`; // Keycloak profile URL
      window.open(profileUrl, '_blank');
    }
  };
  // Determine the active route for highlighting the Results button
  const isResultsPage = location.pathname.startsWith('/results');
  const isAllSimulationsPage = location.pathname === '/';

  const downloadMenu = <Menu>
    <MenuItem text="Linux (.tar.gz)" href="https://download.cppmodel.com/CppModel-0.2.1-Linux.tar.gz" />
    <MenuItem text="Linux (.deb)" href="https://download.cppmodel.com/cppmodel_0.2.1-r1_amd64.deb" />
    <MenuItem text="Windows Visual Studio (.zip)" href="https://download.cppmodel.com/CppModel-0.2.1-win64.zip" />
    <MenuItem text="Windows Msys2 UCRT64 (.zip)" href="https://download.cppmodel.com/CppModel-0.2.1-Windows-UCRT64.zip" />
    {/* <MenuItem text="Linux (.tar.gz)" href="#" />
    <MenuItem text="Windows (.exe)" href="#" />
    <MenuItem text="Mac (.dmg)" href="#" /> */}
  </Menu>
  const newSimulationMenu = <Menu>
    <MenuItem text="Simulation" onClick={openModal} />
    <MenuItem text="Model" />
    <MenuItem text="Project">
      <MenuItem text="CMake" />
      <MenuItem text="Makefile" />
    </MenuItem>
  </Menu>
  const userMenu = <Menu>
    <MenuItem text="Profile" onClick={openUserProfile} target="_blank" />
    {(subscriptionAdmin === 'admin') && <MenuItem text="Subscription" href="https://billing.stripe.com/p/login/test_9AQg2G73n8nk81yaEE" target="_blank" />}
  </Menu>

  return (
    <>
      <Navbar className={Classes.DARK}>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>CppModel Workspace</NavbarHeading>
          <NavbarDivider />
          <Link to="/">
            <Button
              text="All Simulations"
              active={isAllSimulationsPage}
              minimal
              rightIcon={IconNames.Home}
            />
          </Link>
          <Button
            text="Results"
            disabled={!isResultsPage}
            active={isResultsPage}
            minimal
            rightIcon={IconNames.TimelineLineChart}
          />
          <Popover content={newSimulationMenu}>
            <Button
              text="New"
              minimal
              rightIcon={IconNames.SeriesAdd}
            />
          </Popover>
          <NavbarDivider />
          <Tooltip content="Open documentation in new window">
            <AnchorButton
              text="Documentation"
              href="https://www.cppmodel.com/docs/Overview"
              target="_blank"
              minimal
              rightIcon={IconNames.Help}
            />
          </Tooltip>
          <Popover content={downloadMenu}>
            <Button
              text="Download"
              minimal
              rightIcon={IconNames.Download}
            />
          </Popover>
          <NavbarDivider />
          <Popover content={userMenu}>
            <Button
              text={`${(firstName) ? firstName : email}`}
              minimal
              rightIcon={IconNames.User}
            />
          </Popover>
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <AnchorButton
            text="Logout"
            onClick={() => auth.signoutRedirect()}
            minimal
            rightIcon={IconNames.LogOut}
          />
        </NavbarGroup>
      </Navbar>
      <CreateSimulationModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};
