import React, { useState } from 'react';
import { Dialog, Button, FormGroup, InputGroup, Checkbox, HTMLSelect, Spinner } from '@blueprintjs/core';
import JSZip from 'jszip';
// import { saveAs } from 'file-saver';

interface CreateSimulationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateSimulationModal: React.FC<CreateSimulationModalProps> = ({ isOpen, onClose }) => {
    const [simulationName, setSimulationName] = useState('SimulationName');
    const [duration, setDuration] = useState(20);
    const [stepSize, setStepSize] = useState(0.01);
    const [modelLanguage, setModelLanguage] = useState('C++');
    // const [runnable, setRunnable] = useState(false);
    // const [isGenerating, setIsGenerating] = useState(false);
    const [includeModel, setIncludeModel] = useState(false);


    // Make sure simulation name contains only alphanumeric characters and underscores, and starts with a letter
    const handleSimulationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^[a-zA-Z][a-zA-Z0-9_]*$/.test(value)) {
            setSimulationName(value);
        }
    };

    // const handleSetStepType = (stepType:String) => {
    //     if (stepType === 'steps') {
    //         setStepUnit('ms');
    //     }
    //     setStepType(stepType);
    // }

    //     const generateMainCpp = () => {
    //         return `
    // #include <iostream>
    // #include <cmath>
    // #include <fstream>
    // #include <vector>
    // #include <string>   
    // #include <sstream>
    // #include <iomanip>

    // int main() {
    //     std::cout << "Hello, World!" << std::endl;
    //     return 0;
    // }`;
    //     }

    const generateSimulationCpp = () => {
        return `#include <iostream>

#include "cppmodel/Simulation.h"

class ${simulationName} : public CppModelBase::Simulation
{
public:
    ${simulationName}()
    {
        stepSize = ${stepSize};
        time = ${duration};

        name = "${simulationName}";
    }

    virtual ~${simulationName}()
    {
    }

    virtual void Init() override;
    {
        std::cout << "${simulationName}::Initialize()" << std::endl;
    }

    virtual void RunCyclic(double stepTime)
    {
        inputs["TestInput"] = (int)(std::rand() % 10);
        
        outputs["TestOutput"] = (int)(std::rand() % 10);
        
        double input = inputs["TestInput"];
        double output = outputs["TestOutput"];

        bool verify = input < output;

        outputs["CppModel.StepResult"] = (int)verify;
        outputs["CppModel.Step"] = (int)stepTime;
    }

    virtual void Finalize()
    {
        std::cout << "${simulationName}::Finalize()" << std::endl;
    }
};

int main(void)
{
    ${simulationName} simulation;
    simulation.Simulate();
    return simulation.GetSimulationResult();
}
`;
    }

    // const generateModelCpp = () => {
    //     return `#include <iostream>`;
    // }

    const createSimulation = () => {
        // Generate two .cpp files and archive them into simulation.zip
        // const mainCpp = generateMainCpp();
        // const simulationCpp = generateSimulationCpp();
        const zip = new JSZip();

        // zip.file('main.cpp', mainCpp);
        zip.file(`${simulationName}.cpp`, generateSimulationCpp());
        // if (includeModel) {
        //     const modelCpp = generateModelCpp();
        // }

        zip.generateAsync({ type: 'blob' }).then((content) => {
            // Trigger download of the generated file
            // const blob = new Blob(content, { type: 'application/zip' });
            const url = URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${simulationName}.zip`;
            link.click();

            // Clean up
            URL.revokeObjectURL(url);
            // setIsGenerating(false);
            onClose();
        });
    }

    // const handleCreate = async () => {
    //     setIsGenerating(true);

    //     // Simulate archive generation with a timeout (replace this with actual generation logic)
    //     await new Promise((resolve) => setTimeout(resolve, 2000));

    //     // Trigger download of the generated file
    //     const blob = new Blob(['Generated archive content'], { type: 'application/zip' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = `${simulationName}_simulation.zip`;
    //     link.click();

    //     // Clean up
    //     URL.revokeObjectURL(url);
    //     setIsGenerating(false);
    //     onClose();
    // };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Create New Simulation"
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
        >
            <div className="bp4-dialog-body" style={{ padding: '10px' }}>
                <FormGroup label="Simulation Name" labelFor="simulation-name">
                    <InputGroup
                        id="simulation-name"
                        value={simulationName}
                        onChange={(e) => handleSimulationNameChange(e)}
                        placeholder="Enter simulation name"
                    />
                </FormGroup>

                <FormGroup label="Duration (s)" labelFor="duration">
                    <InputGroup
                        id="duration"
                        type="number"
                        value={duration.toString()}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        placeholder="Enter duration"
                    />
                </FormGroup>

                <FormGroup label="Step Size (s)" labelFor="step-size">
                    <InputGroup
                        id="step-size"
                        type="number"
                        value={stepSize.toString()}
                        onChange={(e) => setStepSize(Number(e.target.value))}
                        placeholder="Enter step size"
                    />
                </FormGroup>

                <FormGroup label="Model" >
                    <Checkbox label='Include Model' checked={includeModel} onClick={() => setIncludeModel(!includeModel)} />
                    <HTMLSelect
                        id="model-language"
                        value={modelLanguage}
                        onChange={(e) => setModelLanguage(e.currentTarget.value)}
                        options={['C', 'C++']}
                        disabled={!includeModel}
                    />
                </FormGroup>

                {/* <Checkbox
                    label="Runnable"
                    checked={runnable}
                    onChange={(e) => setRunnable(e.currentTarget.checked)}
                /> */}
            </div>

            <div className="bp4-dialog-footer" style={{ padding: '20px' }}>
                <div className="bp4-dialog-footer-actions">
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button intent="primary" onClick={createSimulation}>
                        Create
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateSimulationModal;
