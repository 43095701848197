import React, { useState } from "react";
import Plot from "react-plotly.js";
import { ApiGraphData } from "./SimulationResults";


interface SimulationGraphProps {
  title?: string;
  data: ApiGraphData;
}

const SimulationGraph: React.FC<SimulationGraphProps> = ({ title = "Plot", data }) => {
  const [zoomRange, setZoomRange] = useState<[number, number] | null>(null);
  const [plotData, setPlotData] = useState([] as Partial<Plotly.ScatterData>[]);

  // console.log("Simulation Graph Data:", data);

  const loadGraphData = React.useCallback(() => {
    const inputColors = [
      "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd",
      "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf",
    ];

    const resultColors = [
      "#393b79", "#637939", "#8c6d31", "#843c39", "#7b4173",
      "#a55194", "#d6616b", "#e7969c", "#f7b6d2", "#c7c7c7",
    ];

    // Convert into Plotly.js format with cyclic colors
    let graphData: Partial<Plotly.ScatterData>[] = [];
    let verificationData: Partial<Plotly.ScatterData>[] = [];
    if (data.inputs !== undefined) {
      graphData.push(
        ...data.inputs.map((input, index) => ({
          x: input.x,
          y: input.y,
          type: "scatter",
          mode: "lines",
          name: `In: ${input.label}`,
          line: { shape: "hv", color: inputColors[index % inputColors.length] }, // Cycle input colors
        })) as Partial<Plotly.ScatterData>[],
      );
    }
    if (data.results !== undefined) {
      graphData.push(
        ...data.results?.map((result, index) => {
          if (!result.label.includes('CppModel.')) {
            return ({
              x: result.x,
              y: result.y,
              type: "scatter",
              mode: "lines",
              name: `Out: ${result.label}`,
              line: { shape: "hv", color: resultColors[index % resultColors.length] }, // Cycle result colors
            })
          } else if (result.label.includes('CppModel.StepResult')) {
            verificationData.push(
              {
                x: result.x,
                y: result.y.map((v) => (v) ? 0 : 1), // Inverted binary signal
                showlegend: false,
                type: "scatter",
                mode: "lines",
                fill: "tozeroy",
                line: { shape: "hv", width: 0, color: "rgb(0, 0, 0)" }, // "hv" makes it step-like
                fillcolor: "rgba(255, 25, 0, 0.5)", // Green fill for active
                name: "Failure",
                xaxis: "x",
                yaxis: "y2",
                hoverinfo: "none", // Hide hover info for binary signal
              });
            return (
              {
                x: result.x,
                y: result.y,
                showlegend: false, // Hide from legend
                type: "scatter",
                mode: "lines",
                fill: "tozeroy",
                line: { shape: "hv", width: 0, color: "rgb(0, 0, 0)" }, // "hv" makes it step-like
                fillcolor: "rgba(0, 255, 0, 0.5)", // Green fill for active
                name: "Success",
                xaxis: "x",
                yaxis: "y2",
                hoverinfo: "none", // Hide hover info for binary signal
              }
            )
          }
          // else if (result.label.includes('CppModel.Step')) {
          //   // From result.y get only unique values
          //   const uniqueValues = new Set(result.y);
          //   const uniqueValuesText = Array.from(uniqueValues).map((v) => `${v}`);
          //   // From result.x get every first value for each unique value in result.y
          //   const uniqueX = result.x.filter((_, i) => result.y[i] !== result.y[i - 1]);
          //   // console.log("Unique X:", uniqueX);
          //   // console.log("Unique Y:", uniqueValues);
          //   verificationData.push(
          //     // Step number annotations
          //     {
          //       x: uniqueX,
          //       y: new Array(uniqueX.length).fill(1), // Position slightly above graph
          //       mode: "markers",
          //       marker: { size: 2, color: "rgb(73, 73, 73)" },
          //       // text: uniqueValuesText,
          //       textposition: "middle center",
          //       showlegend: false,
          //       xaxis: "x",
          //       yaxis: "y2",
          //       hoverinfo: "none", // Hide hover info for step numbers
          //     }
          //   );
          //   return ({});

          // }
          return {};
        }) as Partial<Plotly.ScatterData>[],
      );
    }
    graphData.push(...verificationData);

    // console.log("Heatmap data:", heatmapData);

    setPlotData(graphData);
  }, [data]);

  // Load parameters when component is mounted
  React.useEffect(() => {
    loadGraphData();
  }
    , [data, loadGraphData]);

  const handleZoom = (event: any) => {
    if (event["xaxis.range"]) {
      const newRange: [number, number] = event["xaxis.range"];
      setZoomRange(newRange); // Sync X-axis zoom across both graphs
    }
  };

  return (
    <Plot
      // data={[
      //   // Simulation results (line graph)
      //   {
      //     x: simulationData.x,
      //     y: simulationData.y.map((v) => v * 10), // Adjust for visibility on the same Y scale
      //     type: "scatter",
      //     mode: "lines",
      //     line: { color: "blue", width: 2 },
      //     name: "Simulation Data",
      //     xaxis: "x",
      //     yaxis: "y",
      //   },
      //   // Binary step-like signal (filled)
      //   {
      //     x: binaryStepData.stepX,
      //     y: binaryStepData.stepY,
      //     showlegend: false, // Hide from legend
      //     type: "scatter",
      //     mode: "lines",
      //     fill: "tozeroy",
      //     line: { shape: "vh", width: 0, color: "rgba(0, 255, 0, 1)" }, // "hv" makes it step-like
      //     fillcolor: "rgba(0, 255, 0, 0.5)", // Green fill for active
      //     name: "Success",
      //     xaxis: "x",
      //     yaxis: "y2",
      //     hoverinfo: "none", // Hide hover info for binary signal
      //   },
      //   {
      //     x: binaryStepData.stepX,
      //     y: invertedBinary,
      //     showlegend: false,
      //     type: "scatter",
      //     mode: "lines",
      //     fill: "tozeroy",
      //     line: { shape: "hv", width: 0, color: "rgba(0, 255, 0, 1)" }, // "hv" makes it step-like
      //     fillcolor: "rgba(255, 25, 0, 0.5)", // Green fill for active
      //     name: "Failure",
      //     xaxis: "x",
      //     yaxis: "y2",
      //     hoverinfo: "none", // Hide hover info for binary signal
      //   },
      //   // Step number annotations
      //   {
      //     x: binaryStepData.stepTextX,
      //     y: new Array(binaryStepData.stepTextX.length).fill(0.5), // Position slightly above graph
      //     text: binaryStepData.stepText,
      //     mode: "text",
      //     textposition: "middle center",
      //     showlegend: false,
      //     xaxis: "x",
      //     yaxis: "y2",
      //     hoverinfo: "none", // Hide hover info for step numbers
      //   },
      // ]}
      data={plotData}
      layout={{
        // title: "Simulation Data with Step Binary Signal",
        autosize: true, // Ensure full scaling inside parent
        dragmode: "pan", // Enable panning
        margin: { t: 50, l: 50, r: 50, b: 40 },
        grid: { rows: 2, columns: 1, roworder: "top to bottom" },
        xaxis: {
          title: "Time",
          domain: [0, 1],
          range: zoomRange || undefined, // Sync X-axis zoom
          showticklabels: true, // Show X labels on the main graph
        },
        yaxis: {
          title: "Simulation Value",
          domain: [0.2, 1], // Assigns 80% of space to main graph
        },
        xaxis2: {
          title: "Time",
          domain: [0, 1],
          anchor: "y2",
          range: zoomRange || undefined, // Ensure sync with main X-axis
          showgrid: false, // Hide grid for cleaner look
          showticklabels: false, // Hide X labels for the bottom graph
        },
        yaxis2: {
          title: "Binary Signal",
          domain: [0, 0.1], // Assigns 10% of space to the binary graph
          range: [-0.1, 1.1], // Keep within 0 and 1
          showticklabels: false, // Hide Y labels for clarity
          fixedrange: true, // **Prevents Y zooming**
        },
      }}
      onRelayout={handleZoom} // **Handles zoom sync**
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%", flexGrow: 1 }}
      useResizeHandler={true}
    />
  );
};

export default SimulationGraph;
