import React, { useState } from 'react';
import { Card, Elevation, H5, Button, InputGroup, Tooltip, Icon } from '@blueprintjs/core';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { ParametersCard } from './ParametersCard';
// import HeatMapCard from './HeatmapCard';
import SimulationGraph from './SimulationGraph';

// interface Parameter {
//     name: string;
//     value: string;
// };

export interface ApiGraphData {
    inputs: { x: number[], y: number[], label: string }[],
    results: { x: number[], y: number[], label: string }[],
    parameters: Map<string, number>
    executionTime: string
};

const SimulationResults: React.FC = () => {
    const location = useLocation();
    const simulationName = location.state?.simulationName;
    const rawData = useLoaderData() as ApiGraphData;
    const [executionTime] = useState(rawData.executionTime);
    const navigate = useNavigate();

    // console.log("Simulation Results:", rawData);

    const [duration, setDuration] = useState(60);
    const [stepSize, setStepSize] = useState(1);
    const [isTableCollapsed, setIsTableCollapsed] = useState(true);

    const toggleTableCollapse = () => setIsTableCollapsed(!isTableCollapsed);

    const handleRunSimulation = () => {
        // console.log("Re-executing simulation with parameters:", parameters);
        // console.log("Duration:", duration, "Step size:", stepSize);
    };

    const handleSaveParameters = () => {
        // console.log("Saving parameters:", parameters);
    }

    const loadSimulationParameters = React.useCallback(() => {
        Object.entries(rawData.parameters).forEach(([name, value]) => {
            if (name === 'CppModel.SimulationTime') {
                setDuration(value);
            } else if (name === 'CppModel.SimulationStepSize') {
                setStepSize(value);
            }
        });
    }
    , [rawData, setDuration, setStepSize]);

    // Load parameters when component is mounted
    React.useEffect(() => {
        loadSimulationParameters();
    }, [rawData, loadSimulationParameters]);

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header with Results Heading and Show/Hide Parameters Button */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <H5>Results for {simulationName}</H5>
                <Button
                    icon={isTableCollapsed ? "caret-down" : "caret-up"}
                    minimal
                    onClick={toggleTableCollapse}
                >
                    {isTableCollapsed ? 'Show Parameters' : 'Hide Parameters'}
                </Button>
            </div>

            {/* Flex Container for Graph and Parameters */}
            <div style={{ display: 'flex', flex: 1, gap: '10px', marginTop: '10px', marginBottom: '60px' }}>

                {/* Expandable Simulation Results Card */}
                <Card
                    elevation={Elevation.TWO}
                    style={{
                        flex: isTableCollapsed ? 1 : 2, // Expand graph when parameters are collapsed
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '5px'
                    }}
                >
                    {/* Placeholder for the Simulation Graph */}
                    {/* <HeatMapCard data={rawData} /> */}
                    <SimulationGraph data={rawData} />
                    {/* <GraphCard title="Simulation Results" data={plotData} /> */}
                </Card>

                <ParametersCard isTableCollapsed={isTableCollapsed} toggleTableCollapse={toggleTableCollapse} />
            </div>

            {/* Sticky Footer with Simulation Controls */}
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#f8f8f8',
                padding: '10px 20px',
                borderTop: '1px solid #ddd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <span>Run at: {executionTime ?? 'unknown'}</span>
                <Tooltip content='Verification result'>
                    <Icon icon={IconNames.TickCircle} />
                </Tooltip>
                <div>Simulation Duration
                    <InputGroup
                        placeholder="Duration"
                        // type="number"
                        value={duration.toString()}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        style={{ width: '80px' }}
                    />
                </div>
                <span>Execution Step Size
                    <InputGroup
                        placeholder="Step Size"
                        // type="number"
                        value={stepSize.toString()}
                        onChange={(e) => setStepSize(Number(e.target.value))}
                        style={{ width: '80px' }}
                    />
                </span>
                <Tooltip content="Refresh the simulation results">
                    <Button intent="primary" onClick={()=> navigate(0)} icon={IconNames.Refresh}>
                        Refresh
                    </Button>
                </Tooltip>
                <Tooltip content="Save the parameters for next execution">
                    <Button intent="primary" onClick={handleSaveParameters} icon={IconNames.TableSync} disabled={true}>
                        Save Parameters
                    </Button>
                </Tooltip>
                <Tooltip content="You need to use RunnableSimulation to enable running from workspace">
                    <Button intent="primary" onClick={handleRunSimulation} icon={IconNames.Play} disabled={true}>
                        Run
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

export default SimulationResults;
