import React from "react";
import {
  Alignment,
  Navbar,
  NavbarGroup,
  NavbarDivider,
  Button,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
// import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";

export interface SimulationsNavigationProps { }

export const SimulationsNavigation: React.FC<SimulationsNavigationProps> = () => {
  // const auth = useAuth();
  // const email = auth.user?.profile.email;
  // const groups = auth.user?.profile.groups as string[];

  // const handleMySimulations = () => {
  //   console.log(`Simulations for ${email}`);
  // }

  // const handleTeamSimulations = () => {
  //   const team = groups[0];
  //   console.log(groups);
  //   console.log(`Team simulations for ${team}`);
  // }

  return (
    <Navbar >
      <NavbarGroup align={Alignment.LEFT}>
        <Tooltip content="Simultions which you were the last one running.">
          <Link to="/">
            <Button
              text="My Simulations"
              minimal
              rightIcon={IconNames.User}
            />
          </Link>
        </Tooltip>
        <NavbarDivider />
        <Tooltip content="All simulations in your team's workspace.">
          <Link to="/team-simulations">
            <Button
              text="Team Simulations"
              minimal
              rightIcon={IconNames.Team}
            />
          </Link>
        </Tooltip>
      </NavbarGroup>
    </Navbar >
  );
};
