import React from 'react';
import { SimulationsNavigation } from './SimulationsNavigation';
import { SimulationList } from './SimulationList';
// import { useLoaderData } from 'react-router-dom';

export const AllSimulations: React.FC<{scope:string}> = ({scope='user'}) => {
    // const data = useLoaderData();
    return (
        <>
        <SimulationsNavigation />
        <SimulationList scope={scope} />
        </>
    );
};
