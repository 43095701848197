import React, { useState } from 'react';
import { Card, Elevation, H5, InputGroup, Collapse } from '@blueprintjs/core';
import { useLoaderData } from 'react-router-dom';

export interface Parameter {
  name: string;
  value: string;
}

export const ParametersCard: React.FC<{ isTableCollapsed: boolean; toggleTableCollapse: () => void }> = ({
  isTableCollapsed,
  toggleTableCollapse,
}) => {
  // Get simulationParameters from parent component
  const results = useLoaderData() as { parameters: Map<string, number> };

  const [simulationParameters, setSimulationParameters] = useState<Parameter[]>([]);
  // const [workspaceParameters, setWorkspaceParameters] = useState<Parameter[]>([]);

  const handleParameterChange = (setParams: React.Dispatch<React.SetStateAction<Parameter[]>>, index: number, newValue: string) => {
    setParams((prevParams) => {
      const updatedParams = [...prevParams];
      updatedParams[index].value = newValue;
      return updatedParams;
    });
  };

  const loadParameters = React.useCallback(() => {
    // Parse from {SomeParameter 0: 10, SomeParameter 1: 53, SomeParameter 2: 54, SomeParameter 3: 23, SomeParameter 4: 16}
    // set only if parameter name contains 'CppModel'
    const simParameters = Object.entries(results.parameters).map(([name, value]) => {
      if (!name.includes('CppModel.')) {
        return { name, value: value.toString() };
      }
      return null;
    }).filter((param) => param !== null) as Parameter[];
    // const parameters = Object.entries(results.parameters).map(([name, value]) => {
    //   if (name.includes('CppModel.')) {
    //     return { name, value: value.toString() };
    //   }
    //   return null;
    // }).filter((param) => param !== null) as Parameter[];

    // console.log("Loaded parameters:", parameters);
    setSimulationParameters(simParameters);
    // setWorkspaceParameters(parameters);
  }, [results]);

  // Load parameters when component is mounted
  React.useEffect(() => {
    loadParameters();
  }, [loadParameters]);

  return (
    <Card
      elevation={Elevation.TWO}
      style={{
        flex: isTableCollapsed ? 0.01 : 0.5, // Reduce width to half when collapsed
        padding: isTableCollapsed ? '10px 5px' : '15px',
        overflow: 'hidden',
        transition: 'flex 0.3s ease, padding 0.3s ease' // Smooth transition for flex and padding
      }}
    >
      <Collapse isOpen={!isTableCollapsed}>
        {/* Simulation Parameters Section */}
        <div>
          <H5>Simulation Parameters</H5>
          <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered" style={{ width: '100%', marginTop: '10px' }}>
            <thead>
              <tr>
                <th>Parameter</th>
                <th align='right'>Value</th>
              </tr>
            </thead>
            <tbody>
              {simulationParameters.map((parameter, index) => (
                <tr key={index}>
                  <td>{parameter.name}</td>
                  <td align='right'>
                    <InputGroup
                      value={parameter.value}
                      onChange={(e) => handleParameterChange(setSimulationParameters, index, e.target.value)}
                      placeholder="Enter value"
                      style={{ width: '80px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* CppModel Parameters Section
        <div style={{ marginTop: '20px' }}>
          <H5>Workspace Parameters</H5>
          <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered" style={{ width: '100%', marginTop: '10px' }}>
            <thead>
              <tr>
                <th>Parameter</th>
                <th align='right'>Value</th>
              </tr>
            </thead>
            <tbody>
              {workspaceParameters.map((parameter, index) => (
                <tr key={index}>
                  <td>{parameter.name}</td>
                  <td align='right'>
                    <InputGroup
                      value={parameter.value}
                      onChange={(e) => handleParameterChange(setWorkspaceParameters, index, e.target.value)}
                      placeholder="Enter value"
                      style={{ width: '80px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </Collapse>
    </Card>
  );
};
